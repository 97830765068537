@font-family-square: 'SquareSerifRegular', sans-serif;
@font-family-square-medium: 'SquareSerifMediumRegular', sans-serif;
@font-family-montserrat: 'Montserrat', sans-serif;
@textcolor: rgb(11,3,146);

@font-face {
	font-family: 'SquareSerifMediumRegular';
	src: url('../fonts/SquareSerif-Medium-webfont.eot');
	src: url('../fonts/SquareSerif-Medium-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/SquareSerif-Medium-webfont.woff') format('woff'),
	url('../fonts/SquareSerif-Medium-webfont.ttf') format('truetype'),
	url('../fonts/SquareSerif-Medium-webfont.svg#SquareSerifMediumRegular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'SquareSerifRegular';
	src: url('../fonts/SquareSerif-Book-webfont.eot');
	src: url('../fonts/SquareSerif-Book-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/SquareSerif-Book-webfont.woff') format('woff'),
	url('../fonts/SquareSerif-Book-webfont.ttf') format('truetype'),
	url('../fonts/SquareSerif-Book-webfont.svg#SquareSerifRegular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/Montserrat-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/Montserrat-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

.documentpreview{
	font-family: @font-family-montserrat;

	& .absenderzeile, & .absenderfeld, & .logo{ display: none; }

	.betreff{ margin: 1em 0; font-weight: bold; }
	.datum{ margin: 1em 0; }

	strong{ font-weight: bold; }

	& .positionen{
		margin-top: 2em;
		width: 100%;

		thead{
			border-bottom: 1px solid black;
		}

		small{
			font-size: 0.9em;
			color: rgb(60,60,60);
		}

		tr td:first-child, tr th:first-child{ padding-left: 1em; }
		tr td:last-child, tr th:last-child{ padding-right: 1em; }

		td, th{
			vertical-align: top;
			padding: 3px 5px;
			padding-bottom: 0.5em;
		}

		th{
			font-family: @font-family-square;
			font-weight: normal;
			padding-bottom: 1px;
			color: rgb(48,48,48);
		}

		.leer{
			padding-top: 6rem;
		}

		.summary{
			text-align: right;

			& td{ padding-bottom: 0; }
		}

		.currency{ text-align: right; }
		.align-center{ text-align: center; }
		.align-left{ text-align: left; }

		.nr{ width: 5%; }
		.bezeichnung{ width: 50%; }
		.menge{ width: 16%; }
		.einzelpreis{ width: 14%; }
		.gesamtpreis{ width: 14%; }
	}

	.actionbuttons{ margin-top: 2em; }
}

@media print{
	* {-webkit-print-color-adjust: exact;}
	body { color: #000;	background: #fff; }
	.container{ width: 100%; margin: 0; padding: 0; }
	@page{ size: A4 portrait; margin: 0; padding: 0; }

	.navbar, .container > h1, .container > hr, .actionbuttons, .alert{ display: none; }

	.documentpreview{
		position: relative;
		top: 0;
		left: 0;
		width: 185mm;
		height: 290mm;
		padding: 25mm;
		box-sizing: border-box;

		& .absenderzeile, & .absenderfeld, & .logo{ display: block!important; }

		& .empfaengerfeld, & .absenderfeld, & .betreff, & .datum, & .introtext, & .logo{ position: absolute; }

		& .empfaengerfeld{
			top: 50mm;
			width: 10cm;
			font-size: 11pt;
			line-height: 1.25em;

			& .absenderzeile{
				margin-top: -5mm;
				margin-left: -1.75mm;
				font-size: 9pt;
				font-family: @font-family-square;
				color: @textcolor!important;
			}
		}

		& .logo{
			top: 50mm;
			left: 139mm;
			width: 45mm;
		}

		& .absenderfeld{
			top: 59mm;
			left: 139mm;
			width: 52mm;
			font-size: 9pt;
			line-height: 1.15em;
			font-family: @font-family-square-medium;
			color: @textcolor!important;

			& small{ color: @textcolor!important; }
		}

		& .betreff{ top: 99mm; font-size: 10pt; margin: 0; }
		& .datum{ top: 99mm; left: 100mm; width: 25mm; text-align: right; font-size: 10pt; margin: 0; }

		& .introtext{ top: 121mm; font-size: 10pt; }

		& .positionen{
			position: absolute;
			top: 144mm;
			width: 167mm;
			margin-left: 0;
			margin-top: 0;

			& th, & td{ font-size: 9pt; }
		}
	}
}