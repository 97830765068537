@import "bootstrap/bootstrap";

@btn-font-weight: 300;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;

body, label, .checkbox label {
	font-weight: 300;
}

.documentslist{
	width: 100%;

	& th, & td{ padding: 3px 0.5em; }

	& .align-right{ text-align: right; }
	& .align-center{ text-align: center; }

	& thead{
		font-weight: 700;

		& th{ cursor: pointer; }
	}
}

div.alert-success{
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	margin: 0;
}

ul.alert{
	list-style-position: inside;
}

.recipients_list{
	list-style-type: none;
	margin: 2em 0 0;
	padding: 0;

	& li{
		margin-bottom: 0.5em;
	}
}

.positionen_liste{
	 & .position{
		 border-radius: 4px;
		 background: rgb(240,240,240);
		 padding: 0.4em 0.4em 0 0.4em;
		 margin-bottom: 0.8em;
	 }
 }

.offeneRechnungen, .umsaetze{
	padding-left: 0;
	list-style: none;
	margin-top: 0.5rem;

	li{ margin-bottom: 0.2rem; }
}

.select2-container--default .select2-selection--single{ border-color: #ccc; }
.select2-container .select2-selection--single, .select2-container--default .select2-selection--single .select2-selection__arrow{ height: 34px; }

.select2-container--default .select2-selection--single .select2-selection__rendered{ line-height: 34px; }