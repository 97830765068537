@font-face {
  font-family: 'SquareSerifMediumRegular';
  src: url('../fonts/SquareSerif-Medium-webfont.eot');
  src: url('../fonts/SquareSerif-Medium-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/SquareSerif-Medium-webfont.woff') format('woff'), url('../fonts/SquareSerif-Medium-webfont.ttf') format('truetype'), url('../fonts/SquareSerif-Medium-webfont.svg#SquareSerifMediumRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SquareSerifRegular';
  src: url('../fonts/SquareSerif-Book-webfont.eot');
  src: url('../fonts/SquareSerif-Book-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/SquareSerif-Book-webfont.woff') format('woff'), url('../fonts/SquareSerif-Book-webfont.ttf') format('truetype'), url('../fonts/SquareSerif-Book-webfont.svg#SquareSerifRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
.documentpreview {
  font-family: 'Montserrat', sans-serif;
}
.documentpreview .absenderzeile,
.documentpreview .absenderfeld,
.documentpreview .logo {
  display: none;
}
.documentpreview .betreff {
  margin: 1em 0;
  font-weight: bold;
}
.documentpreview .datum {
  margin: 1em 0;
}
.documentpreview strong {
  font-weight: bold;
}
.documentpreview .positionen {
  margin-top: 2em;
  width: 100%;
}
.documentpreview .positionen thead {
  border-bottom: 1px solid black;
}
.documentpreview .positionen small {
  font-size: 0.9em;
  color: #3c3c3c;
}
.documentpreview .positionen tr td:first-child,
.documentpreview .positionen tr th:first-child {
  padding-left: 1em;
}
.documentpreview .positionen tr td:last-child,
.documentpreview .positionen tr th:last-child {
  padding-right: 1em;
}
.documentpreview .positionen td,
.documentpreview .positionen th {
  vertical-align: top;
  padding: 3px 5px;
  padding-bottom: 0.5em;
}
.documentpreview .positionen th {
  font-family: 'SquareSerifRegular', sans-serif;
  font-weight: normal;
  padding-bottom: 1px;
  color: #303030;
}
.documentpreview .positionen .leer {
  padding-top: 6rem;
}
.documentpreview .positionen .summary {
  text-align: right;
}
.documentpreview .positionen .summary td {
  padding-bottom: 0;
}
.documentpreview .positionen .currency {
  text-align: right;
}
.documentpreview .positionen .align-center {
  text-align: center;
}
.documentpreview .positionen .align-left {
  text-align: left;
}
.documentpreview .positionen .nr {
  width: 5%;
}
.documentpreview .positionen .bezeichnung {
  width: 50%;
}
.documentpreview .positionen .menge {
  width: 16%;
}
.documentpreview .positionen .einzelpreis {
  width: 14%;
}
.documentpreview .positionen .gesamtpreis {
  width: 14%;
}
.documentpreview .actionbuttons {
  margin-top: 2em;
}
@media print {
  * {
    -webkit-print-color-adjust: exact;
  }
  body {
    color: #000;
    background: #fff;
  }
  .container {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  @page {
    size: A4 portrait;
    margin: 0;
    padding: 0;
  }
  .navbar,
  .container > h1,
  .container > hr,
  .actionbuttons,
  .alert {
    display: none;
  }
  .documentpreview {
    position: relative;
    top: 0;
    left: 0;
    width: 185mm;
    height: 290mm;
    padding: 25mm;
    box-sizing: border-box;
  }
  .documentpreview .absenderzeile,
  .documentpreview .absenderfeld,
  .documentpreview .logo {
    display: block!important;
  }
  .documentpreview .empfaengerfeld,
  .documentpreview .absenderfeld,
  .documentpreview .betreff,
  .documentpreview .datum,
  .documentpreview .introtext,
  .documentpreview .logo {
    position: absolute;
  }
  .documentpreview .empfaengerfeld {
    top: 50mm;
    width: 10cm;
    font-size: 11pt;
    line-height: 1.25em;
  }
  .documentpreview .empfaengerfeld .absenderzeile {
    margin-top: -5mm;
    margin-left: -1.75mm;
    font-size: 9pt;
    font-family: 'SquareSerifRegular', sans-serif;
    color: #0b0392 !important;
  }
  .documentpreview .logo {
    top: 50mm;
    left: 139mm;
    width: 45mm;
  }
  .documentpreview .absenderfeld {
    top: 59mm;
    left: 139mm;
    width: 52mm;
    font-size: 9pt;
    line-height: 1.15em;
    font-family: 'SquareSerifMediumRegular', sans-serif;
    color: #0b0392 !important;
  }
  .documentpreview .absenderfeld small {
    color: #0b0392 !important;
  }
  .documentpreview .betreff {
    top: 99mm;
    font-size: 10pt;
    margin: 0;
  }
  .documentpreview .datum {
    top: 99mm;
    left: 100mm;
    width: 25mm;
    text-align: right;
    font-size: 10pt;
    margin: 0;
  }
  .documentpreview .introtext {
    top: 121mm;
    font-size: 10pt;
  }
  .documentpreview .positionen {
    position: absolute;
    top: 144mm;
    width: 167mm;
    margin-left: 0;
    margin-top: 0;
  }
  .documentpreview .positionen th,
  .documentpreview .positionen td {
    font-size: 9pt;
  }
}
